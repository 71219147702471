<template>
  <MemberSettingContainer>
    <div class="d-flex mb-2">
      <h4>
        {{ $t("myself.menu.contact-info") }} 
      </h4>
    </div>

    <ContactData />

  </MemberSettingContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import MemberSettingContainer from '@/views/myself/components/MemberSettingContainer.vue';

export default {
  components: {
    MemberSettingContainer,
    ContactData: () => import('@/views/myself/components/contact/ContactData.vue' /* webpackChunkName: "ContactData" */),
  },
  mixins: [],
  data() {
    return {
    };
  },
  setup() {
    //
  },
  computed: {
    ...mapGetters(['loggedMember', 'currentCollective']),
    
    isSaveEnabled() {
      return (
        this.form.name
        && this.form.slug
        && (this.form.accessibilityAux || this.form.accessibility)
        && (this.form.visibilityAux || this.form.visibility)
      );
    },
    isSubcommunity() {
      return this.currentCollective.parentKey != null;
    },
    isDisabled() {
      return !this.form.name || !this.form.slug || !this.form.visibility || !this.form.accessibility;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    helpLocation() {
      return process.env.VUE_APP_HELP_CENTER;
    },
  },
  async created() {
    this.isStaff = this.currentCollective.isStaff;
  },
  methods: {

  },
};
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.settings-form {
  @include media-breakpoint-up(md) {
    width: 75%;
  }
  @include media-breakpoint-up(lg) {
    width: 50%;
  }
  .avatar {
    width: 80px !important;
    height: 80px !important;
  }
}
.field.has-value:not(.no-label) .field-input[data-v-5b500588] {
  // Class of VueCtkDateTimePicker
  color: $dark;
}
</style>
